import React from "react";

function Services() {
  const elements = [
    {
      id: 1,
      name: "Import/Export of Electronic Goods",
      content:
        "Our import and export services cover a wide range of electronics and machinery goods, including electronic parts, mobile phones, computers, and more.",
    },
    {
      id: 2,
      name: "Sale of Electronic & Machinary Parts",
      content:
        "We offer a wide range of high-quality electronic parts for various applications, including sensors, power supplies, and more.",
    },
    {
      id: 3,
      name: "Import of Branded Electronic Products",
      content:
        "We import branded electronic products from all over the world, ensuring that our customers have access to the latest and greatest technology.",
    },
    {
      id: 4,
      name: "Retail Sale of Electronics Products",
      content:
        "Our retail store offers a wide range of electronics products, including fridges, mobile phones, computers, and more.",
    },
  ];
  return (
    <section id="services" className="font-poppins py-4 md:py-16 mb-6 px-3 md:px-0">
      <div className="container mx-auto">
        <h1 className="max-w-2xl mb-4 text-4xl font-semibold  text-dblue uppercase tracking-tight leading-none md:text-5xl py-2 md:py-8">
          Our Services
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 md:gap-8 space-y-6 md:space-y-0">
          {elements.map(function (element) {
            const { id, name, content } = element;
            return (
              <div key={id} className="flex flex-col space-y-4 justify-center p-4 rounded-xl border-2 hover:border-nblue">
                <p className="text-xl font-semibold capitalize">{name}</p>
                <p>
                  {content}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Services;
