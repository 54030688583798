import React from "react";
import bannerImage1 from "../assets/products/1.png";
import bannerImage2 from "../assets/products/2.png";
import bannerImage3 from "../assets/products/3.png";
// import bannerImage4 from "../assets/products/4.png";
import bannerImage5 from "../assets/products/5.png";

const elements = [
  { id: 1, name: "product 1", price: 100, image: bannerImage1 },
  { id: 2, name: "product 2", price: 200, image: bannerImage2 },
  { id: 3, name: "product 3", price: 300, image: bannerImage3 },
  // { id: 4, name: "product 4", price: 400, image: bannerImage4 },
  { id: 5, name: "product 5", price: 500, image: bannerImage5 },
];
function Products() {
  return (
    <section id="products" className="font-poppins py-2 md:py-16 container mx-auto">
      <h1 className="pb-12 text-3xl md:text-5xl uppercase font-semibold text-center tracking-tight md:text-left">
        products
      </h1>
      <div className="grid grid-cols-1 place-items-center sm:grid-cols-2 md:grid-cols-4 gap-4 px-2 md:px-0">
        {elements.map(function (el) {
          return (
            <div key={el.id} className="card object-cover pb-6">
              <div className="card__image">
                <img
                  src={el.image}
                  alt="product"
                  className={el.id <= 6 ? "h-[270px] w-[320px] object-cover rounded" : "rounded md:block h-[200px] w-[300px] object-cover hidden"}                  
                />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Products;
