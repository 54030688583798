import React from "react";
import { BsFacebook,BsInstagram, BsWhatsapp } from "react-icons/bs";

function Footer() {
  return (
    <section className="font-poppins bg-dblue">
      <footer className="container mx-auto p-4 rounded-lg md:flex md:items-center md:justify-between md:p-6">
        <span className="text-sm text-white sm:text-center">
          © 2023
          <a href="" className="hover:underline">
            RSDSuppliers
          </a>
          . All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm space-x-4 text-white sm:mt-0">
          <li>
            <a href="#"><BsFacebook className="text-2xl text-lblue" /></a>
          </li>
          <li>
            <a href="#"><BsInstagram className="text-2xl text-lblue" /></a>
          </li>
          <li>
          <a href="#"><BsWhatsapp className="text-2xl text-lblue" /></a>
          </li>
          <li></li>
        </ul>
      </footer>
    </section>
  );
}

export default Footer;
